// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

const CategoryPartnersComponent = ({ partners }) => {
    return (
        <>
            <CategoryPartners>
                {partners.map(partner => (
                    <Partner key={partner.id}>
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${
                                partner.address
                            }+${partner.postalCode}+${partner.city}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {partner.name}
                        </a>
                        <br />
                        {partner.address}
                        <br />
                        {partner.postalCode} {partner.city}
                    </Partner>
                ))}
            </CategoryPartners>
        </>
    );
};

const CategoryPartners = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Partner = styled.div`
    ${s.fonts.style('h15')};

    a {
        font-weight: 600;
        &:hover:not(:active) {
            text-decoration: underline;
        }
    }

    ${s.grid.columns('width', {
        0: 4,
        10: 2,
        30: 3,
    })};

    ${s.grid.gutter('margin-bottom', {
        0: 3,
    })};

    &:nth-of-type(2n + 2) {
        ${s.grid.gutter('margin-left', {
            10: 2,
            20: () => '0',
        })};
    }

    &:not(:nth-of-type(3n + 1)) {
        ${s.grid.gutter('margin-left', {
            20: 2,
            30: () => '0',
        })};
    }

    &:not(:nth-of-type(4n + 1)) {
        ${s.grid.gutter('margin-left', {
            30: 2,
        })};
    }
`;

export default CategoryPartnersComponent;
