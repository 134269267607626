// React
import React, { useState, useEffect, useRef } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';
import { css } from '@emotion/react';

// Packages
import AnimateHeight from 'react-animate-height';
import { useGlobal } from 'reactn';

// Utilities
import { useResponsive } from 'hooks';
import { scrollTo, scrollTop } from 'utilities';

// Components
import CategoryPartners from './components/categoryPartners';

const CoffeePartnersComponent = ({
    partners,
    countries,
    pageContext,
    longestName,
}) => {
    const { labels } = pageContext;

    // Create partner countries
    const [partnerCountries, setPartnerCountries] = useState(null);
    useEffect(() => {
        const reducedCountries = countries.reduce((acc, country) => {
            // Find array of partners based on country name
            const partnersInCountry = partners.filter(
                partner => partner.country.id === country.id
            );

            // Only merge if there are any partners in country
            if (partnersInCountry.length > 0) {
                acc = [
                    ...acc,
                    {
                        name: country.name,
                        id: country.id,

                        // Sort in categories
                        partners: {
                            cafe: partnersInCountry.filter(
                                partner => partner.category === 'cafe'
                            ),
                            shop: partnersInCountry.filter(
                                partner => partner.category === 'shop'
                            ),
                            restaurant: partnersInCountry.filter(
                                partner => partner.category === 'restaurant'
                            ),
                        },
                    },
                ];
            }
            return acc;
        }, []);

        setPartnerCountries(reducedCountries);
    }, []);

    // Refs
    let partnerRefs = partners.map(() => useRef(null));

    // Active country
    const [activeCountries, setActiveCountries] = useState([]);

    // Breakpoints
    const breakpoint = useResponsive();

    // Set offsets for scrolling based on breakpoints
    const [offsetBase, setOffsetBase] = useState(150);
    useEffect(() => {
        switch (breakpoint) {
            case 0:
            case 10:
                setOffsetBase(100);
                break;
            case 20:
                setOffsetBase(120);
                break;
            case 30:
                setOffsetBase(130);
                break;
            case 40:
                setOffsetBase(150);
                break;
        }
    }, [breakpoint]);

    // Header
    const [, setForceHeaderDisabled] = useGlobal('forceHeaderDisabled');

    // Scroll to category
    const scrollToSection = (index, timeout = 50) => {
        // Disable header
        setForceHeaderDisabled(true);

        // Make the scroll
        setTimeout(() => {
            scrollTo(scrollTop(partnerRefs[index].current) - offsetBase);
        }, timeout);

        // Enable header
        setTimeout(() => {
            setForceHeaderDisabled(false);
        }, timeout + 200);
    };

    return partnerCountries ? (
        <>
            <Partners>
                {partnerCountries.map((country, index) => (
                    <PartnerCountry key={country.id} ref={partnerRefs[index]}>
                        <Name
                            {...{
                                active: activeCountries.includes(index),
                                longestName,
                            }}
                            onClick={() => {
                                if (activeCountries.includes(index)) {
                                    const newCountries = activeCountries.filter(
                                        bar => bar !== index
                                    );
                                    setActiveCountries(newCountries);
                                } else {
                                    setActiveCountries([
                                        ...activeCountries,
                                        index,
                                    ]);
                                }
                            }}>
                            {country.name}{' '}
                            <span>
                                (
                                {country.partners.cafe.length +
                                    country.partners.restaurant.length +
                                    country.partners.shop.length}
                                )
                            </span>
                        </Name>
                        <AnimateHeight
                            duration={500}
                            height={
                                activeCountries.includes(index) ? 'auto' : 0
                            }
                            onAnimationEnd={() => {
                                if (activeCountries.includes(index)) {
                                    // Get scroll offset
                                    scrollToSection(index);
                                }
                            }}>
                            <PartnerLists>
                                {country.partners.cafe.length > 0 && (
                                    <PartnerList>
                                        <h3>
                                            {labels.PAGES.CONTACT.CAFES} (
                                            {country.partners.cafe.length})
                                        </h3>
                                        <CategoryPartners
                                            {...{
                                                partners: country.partners.cafe,
                                            }}
                                        />
                                    </PartnerList>
                                )}
                                {country.partners.restaurant.length > 0 && (
                                    <PartnerList>
                                        <h3>
                                            {labels.PAGES.CONTACT.RESTAURANTS} (
                                            {country.partners.restaurant.length}
                                            )
                                        </h3>
                                        <CategoryPartners
                                            {...{
                                                partners:
                                                    country.partners.restaurant,
                                            }}
                                        />
                                    </PartnerList>
                                )}
                                {country.partners.shop.length > 0 && (
                                    <PartnerList>
                                        <h3>
                                            {labels.PAGES.CONTACT.SHOPS} (
                                            {country.partners.shop.length})
                                        </h3>
                                        <CategoryPartners
                                            {...{
                                                partners: country.partners.shop,
                                            }}
                                        />
                                    </PartnerList>
                                )}
                            </PartnerLists>
                        </AnimateHeight>
                    </PartnerCountry>
                ))}
            </Partners>
        </>
    ) : null;
};

const PartnerList = styled.div`
    margin-bottom: 1em;
    h3 {
        ${s.fonts.style('h13')};
        padding-bottom: 0.3em;
        border-bottom: 1px solid ${s.color('black').default()};
        margin-bottom: 1em;
    }
`;

const PartnerLists = styled.div`
    ${s.responsive.property(['padding-top', 'padding-bottom'], {
        0: '2em',
        30: '4em',
    })};
`;

const PartnerCountry = styled.div`
    margin-bottom: 1.3em;
`;

const Name = styled.div`
    position: relative;
    ${s.fonts.style('h1')};
    font-size: calc(120vw / ${props => props.longestName}) !important;

    display: inline-block;

    cursor: pointer;

    transition: opacity 400ms ${s.easings.smooth.out};
    color: currentColor;
    opacity: 1;
    &:hover {
        opacity: 1 !important;
    }

    ${props =>
        props.active &&
        css`
            opacity: 1 !important;
        `};

    span {
        position: absolute;
        left: 102%;
        bottom: calc(100% - 2.2em);
        ${s.fonts.style('h13')};
    }
`;

const Partners = styled.div`
    display: block;
    color: ${s.color('black').default()};
    margin-bottom: 10em;
    &:hover {
        ${Name} {
            opacity: 0.2;
        }
    }
`;

export default CoffeePartnersComponent;
