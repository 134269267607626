// React
import React, { useEffect, useState } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import _get from 'lodash.get';
import { StickyContainer } from 'react-sticky';

// Utilities
import { localize, scrollTo } from 'utilities';
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import { PageNavigationSticky } from 'components/pageNavigation';
import CoffeeBars from 'components/_contact/coffeeBars';
import CoffeePartners from 'components/_contact/coffeePartners';

const ContactPageComponent = ({ content, location, pageContext }) => {
    const { labels, locale } = pageContext;
    const { seoMetaTags, bars, partners, countries } = content;

    // Define categories
    const categories = [
        { name: labels.PAGES.CONTACT.BARS, category: 'BARS' },
        { name: labels.PAGES.CONTACT.PARTNERS, category: 'PARTNERS' },
    ];

    // Category
    const [category, setCategory] = useState(categories[0]);

    // Product category by location
    useEffect(() => {
        // Is there a category from content that corresponds to the category name from state?
        const categoryList = categories.filter(
            cat => cat.category === _get(location, 'state.category', null)
        );
        const category =
            categoryList.length > 0 ? categoryList[0] : categories[0];
        setCategory(category);
    }, [location]);

    // Set theme
    const theme = useTheme(s.color('white'));

    // Longest name
    const [longestName, setLongestName] = useState(14);
    useEffect(() => {
        // Longest name (for font size)
        const nameLength = bars.reduce((acc, bar) =>
            acc > bar.name.length ? acc : bar.name.length
        );
        setLongestName(nameLength);
    }, []);

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />

                <StickyContainer>
                    <PageNavigationSticky {...{ theme }}>
                        {categories.map(item => (
                            <ContactNavigationElement
                                key={item.name}
                                {...{
                                    active:
                                        item.name ===
                                        _get(category, 'name', null),
                                    onClick() {
                                        scrollTo(0);
                                        setCategory(item);
                                    },
                                }}>
                                {item.name}
                            </ContactNavigationElement>
                        ))}
                    </PageNavigationSticky>
                    <ContentWrapper>
                        {category.category === 'BARS' && (
                            <CoffeeBars
                                {...{ bars, pageContext, theme, longestName }}
                            />
                        )}
                        {category.category === 'PARTNERS' && (
                            <CoffeePartners
                                {...{
                                    partners,
                                    countries,
                                    pageContext,
                                    theme,
                                    longestName,
                                }}
                            />
                        )}
                    </ContentWrapper>
                </StickyContainer>
            </s.layout.PageContainer>
        </>
    );
};

const ContactNavigationElement = styled.span`
    cursor: pointer;

    transition: opacity 0.4s;
    will-change: opacity;
    white-space: nowrap;
    opacity: 0.3;

    &:hover {
        opacity: 1;
    }

    &:focus,
    &:active {
        opacity: 0.3;
    }

    ${props =>
        props.active &&
        css`
            opacity: 1;
        `}
`;

const ContentWrapper = styled.div`
    ${s.responsive.property('margin-top', {
        0: 80,
        30: 100,
    })};

    padding-top: 2em;
`;

const NamedContactPageComponent = props => {
    const { locale } = props.pageContext;
    const {
        allDatoCmsPageContact,
        allDatoCmsCoffeeBar,
        allDatoCmsPartner,
        allDatoCmsCountry,
    } = useStaticQuery(graphql`
        query {
            allDatoCmsPageContact {
                edges {
                    node {
                        locale
                        seoMetaTags {
                            ...SEO
                        }
                    }
                }
            }
            allDatoCmsCoffeeBar(sort: { fields: [position], order: ASC }) {
                edges {
                    node {
                        id
                        locale
                        name
                        image {
                            fluid(maxWidth: 1600) {
                                ...GatsbyDatoCmsFluid_noBase64
                            }
                        }
                        description
                        address
                        postalCode
                        city
                        country {
                            name
                        }
                        openingHours {
                            day
                            from
                            to
                        }
                        links {
                            name
                            link
                        }
                    }
                }
            }
            allDatoCmsPartner(sort: { fields: [name], order: ASC }) {
                edges {
                    node {
                        id
                        locale
                        name
                        address
                        postalCode
                        city
                        country {
                            id
                        }
                        category
                    }
                }
            }
            allDatoCmsCountry(sort: { fields: [name], order: ASC }) {
                edges {
                    node {
                        id
                        locale
                        name
                    }
                }
            }
        }
    `);

    return (
        <ContactPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageContact, locale),
                    bars: localize(allDatoCmsCoffeeBar, locale),
                    partners: localize(allDatoCmsPartner, locale),
                    countries: localize(allDatoCmsCountry, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedContactPageComponent;
